import React from 'react';
import { format,  parseISO } from 'date-fns';
import { FaBuilding } from 'react-icons/fa';

const Experience = ({ language, activeSection, data }) => {
    const isActive = activeSection === 'experience';

    const formatDate = (dateString) => {
        if (!dateString) return language === 'en' ? 'Present' : 'Heden';
        const date = parseISO(dateString);
        return format(date, 'MMM yyyy');
    };

    return (
        <section
            id="experience"
            className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md transform transition-all duration-300 ${
                isActive ? 'scale-105 shadow-2xl' : 'hover:scale-105 hover:shadow-lg'
            }`}
        >
            <h2 className="text-2xl font-bold mb-6 dark:text-white flex items-center">
                <FaBuilding className="mr-2" />
                {language === 'en' ? 'Experience' : 'Ervaring'}
            </h2>
            <div className="space-y-8">
                {data.work &&
                    data.work.map((job, index) => (
                        <div
                            key={index}
                            className="experience-item bg-gray-50 dark:bg-gray-700 p-5 rounded-md shadow-sm"
                        >
                            <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                                <div>
                                    <h3 className="text-xl font-semibold dark:text-white">{job.position}</h3>
                                    <p className="text-md text-gray-600 dark:text-gray-300">
                                        {job.name} - {job.location}
                                    </p>
                                </div>
                                <div className="mt-2 md:mt-0 text-sm text-gray-500 dark:text-gray-400 md:text-right">
                                    <p>
                                        {formatDate(job.startDate)} - {formatDate(job.endDate)}
                                    </p>
                                </div>
                            </div>
                            <p className="mt-4 text-gray-700 dark:text-gray-200">
                                {job.highlights.map((highlight, idx) => (
                                    <span key={idx} className="block mb-2">
                    • {highlight}
                  </span>
                                ))}
                            </p>
                        </div>
                    ))}
            </div>
        </section>
    );
};

export default Experience;
