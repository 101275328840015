import React from 'react';
import { FaGlobe } from 'react-icons/fa';
import { BsMoon, BsSun } from 'react-icons/bs';

const Header = ({ setLanguage, language, darkMode, setDarkMode}) => {
    return (
        <header className="bg-gray-800 dark:bg-gray-900 text-white dark:text-gray-200 py-6 shadow-lg z-50 relative">
            <div className="container mx-auto flex justify-between items-center px-8">
                <div className="flex items-center space-x-4">
                    <img src="/logo.svg" alt="Logo" className="h-10 w-10" />
                    <h1
                        className="text-3xl font-bold tracking-wide"
                        tabIndex="0"
                    >
                        {language === 'en' ? 'Jelmer Franken' : 'Jelmer Franken'}
                    </h1>
                </div>
                <div className="flex items-center space-x-4">
                    <div className="flex items-center space-x-2 bg-gray-700 dark:bg-gray-800 px-3 py-2 rounded-lg focus-within:ring-2 focus-within:ring-blue-400 focus-within:ring-offset-2 focus-within:ring-offset-gray-800 hover:ring-2 hover:ring-blue-400 hover:ring-offset-2 hover:ring-offset-gray-800">
                        <FaGlobe className="text-xl" />
                        <select
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                            className="bg-transparent text-white dark:text-gray-200 focus:outline-none cursor-pointer"
                        >
                            <option value="nl" className="text-black dark:text-white bg-white dark:bg-gray-800">
                                Nederlands
                            </option>
                            <option value="en" className="text-black dark:text-white bg-white dark:bg-gray-800">
                                English
                            </option>
                        </select>
                    </div>
                    <button
                        onClick={() => setDarkMode(!darkMode)}
                        className="p-2 bg-gray-700 dark:bg-gray-800 rounded-lg transition duration-200 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 focus:ring-offset-gray-800 hover:ring-2 hover:ring-blue-400 hover:ring-offset-2 hover:ring-offset-gray-800"
                        aria-label="Toggle Dark Mode"
                    >
                        {darkMode ? <BsSun className="text-2xl text-yellow-300" /> : <BsMoon className="text-2xl text-blue-300" />}
                    </button>
                </div>
            </div>
        </header>
    );
}

export default Header;