import React from 'react';
import { format, parseISO } from 'date-fns';
import { FaProjectDiagram } from 'react-icons/fa';

const Projects = ({ language, activeSection, data }) => {
    const isActive = activeSection === 'projects';

    const formatDate = (dateString) => {
        if (!dateString) return language === 'en' ? 'Present' : 'Heden';
        const date = parseISO(dateString);
        return format(date, 'MMM yyyy');
    };

    return (
        <section
            id="projects"
            className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md transform transition-all duration-300 ${
                isActive ? 'scale-105 shadow-2xl' : 'hover:scale-105 hover:shadow-lg'
            }`}
        >
            <h2 className="text-2xl font-bold mb-6 dark:text-white flex items-center">
                <FaProjectDiagram className="mr-2" />
                {language === 'en' ? 'Projects' : 'Projecten'}
            </h2>
            <div className="space-y-8">
                {data.projects &&
                    data.projects.map((project, index) => (
                        <div
                            key={index}
                            className="project-item bg-gray-50 dark:bg-gray-700 p-5 rounded-md shadow-sm"
                        >
                            <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                                <h3 className="text-xl font-semibold dark:text-white">{project.name}</h3>
                                <div className="mt-2 md:mt-0 text-sm text-gray-500 dark:text-gray-400 md:text-right">
                                    <p>
                                        {formatDate(project.startDate)} - {formatDate(project.endDate)}
                                    </p>
                                </div>
                            </div>
                            <p className="mt-4 text-gray-700 dark:text-gray-200">{project.summary}</p>
                            {project.url && (
                                <p className="mt-2">
                                    <a
                                        href={project.url}
                                        className="text-blue-500 dark:text-blue-400 hover:underline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {project.url}
                                    </a>
                                </p>
                            )}
                            {project.highlights && project.highlights.length > 0 && (
                                <ul className="mt-4 list-disc list-inside text-gray-700 dark:text-gray-200">
                                    {project.highlights.map((highlight, idx) => (
                                        <li key={idx}>{highlight}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
            </div>
        </section>
    );
};

export default Projects;
