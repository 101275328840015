import React from 'react';
import {
    FaMapMarkerAlt,
    FaPhone,
    FaEnvelope,
    FaLinkedin,
    FaGithub,
    FaGlobe,
    FaLanguage,
} from 'react-icons/fa';

const Personal = ({ data, phone,  language }) => {
    const {
        name,
        label,
        image,
        summary,
        location,
        email,
        profiles,
    } = data.basics;

    // Map profiles to icons
    const profileIcons = {
        LinkedIn: <FaLinkedin />,
        GitHub: <FaGithub />,
        Website: <FaGlobe />,
    };

    return (
        <section className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
            {/* Profile Header */}
            <div className="text-center mb-6">
                <img
                    src={image}
                    alt={name}
                    className="w-32 h-32 rounded-full mx-auto mb-4"
                />
                <h2 className="text-2xl font-bold dark:text-white">{name}</h2>
                <p className="text-lg text-gray-600 dark:text-gray-300">{label}</p>
                <a
                    href="/Jelmer Franken - CV - 2024.pdf"
                    download
                    className="mt-4 inline-block bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                >
                    Download CV
                </a>
            </div>
            {/* Summary */}
            <div className="bg-gray-50 dark:bg-gray-700 p-5 rounded-md shadow-sm mb-6">
                <h3 className="text-xl font-semibold dark:text-white mb-2">
                    {language === 'en' ? 'About Me' : 'Over mij'}
                </h3>
                <p className="text-gray-700 dark:text-gray-300">{summary}</p>
            </div>
            {/* Contact Information */}
            <div className="bg-gray-50 dark:bg-gray-700 p-5 rounded-md shadow-sm mb-6">
                <h3 className="text-xl font-semibold dark:text-white mb-2">
                    {language === 'en' ? 'Contact Information' : 'Contactgegevens'}
                </h3>
                <div className="space-y-2 text-gray-700 dark:text-gray-300">
                    <p className="flex items-center">
                        <FaMapMarkerAlt className="mr-2"/>
                        {location.city}, {location.countryCode}
                    </p>
                    <p className="flex items-center">
                        <FaPhone className="mr-2"/>
                        <a href={`tel:${phone}`}>{phone}</a>
                    </p>
                    <p className="flex items-center flex-wrap">
                        <FaEnvelope className="mr-2"/>
                        <a href={`mailto:${email}`} className="break-all">{email}</a>
                    </p>
                </div>
            </div>
            {/* Languages */}
            <div className="bg-gray-50 dark:bg-gray-700 p-5 rounded-md shadow-sm mb-6">
                <h3 className="text-xl font-semibold dark:text-white mb-2">
                    {language === 'en' ? 'Languages' : 'Talen'}
                </h3>
                <div className="space-y-2 text-gray-700 dark:text-gray-300">
                    <p className="flex items-center">
                        <FaLanguage className="mr-2" />
                        {language === 'en' ? 'Dutch (Native)' : 'Nederlands (Moedertaal)'}
                    </p>
                    <p className="flex items-center">
                        <FaLanguage className="mr-2" />
                        {language === 'en' ? 'English (Fluent)' : 'Engels (Vloeiend)'}
                    </p>
                </div>
            </div>
            {/* Professional Profiles */}
            <div className="bg-gray-50 dark:bg-gray-700 p-5 rounded-md shadow-sm mb-6">
                <h3 className="text-xl font-semibold dark:text-white mb-2">
                    {language === 'en' ? 'Profiles' : 'Profielen'}
                </h3>
                <div className="flex space-x-4 text-gray-700 dark:text-gray-300">
                    {profiles.map((profile, index) => (
                        <a
                            key={index}
                            href={profile.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-2xl"
                        >
                            {profileIcons[profile.network] || <FaGlobe />}
                        </a>
                    ))}
                </div>
            </div>
            {/* Availability */}
            <div className="bg-gray-50 dark:bg-gray-700 p-5 rounded-md shadow-sm flex-1">
                <h3 className="text-xl font-semibold dark:text-white mb-2">
                    {language === 'en' ? 'Availability' : 'Beschikbaarheid'}
                </h3>
                <p className="text-gray-700 dark:text-gray-300">
                    {language === 'en'
                        ? 'Available for a graduation internship from December 2024. Preference for on-site.'
                        : 'Beschikbaar voor een afstuderenstage vanaf december 2024. Voorkeur op locatie.'}
                </p>
            </div>
        </section>
    );
};

export default Personal;