import React from 'react';
import {
    FaCode,
    FaCloud,
    FaLock,
    FaTools,
    FaLightbulb,
} from 'react-icons/fa';

const Skills = ({ activeSection, data, language }) => {
    const isActive = activeSection === 'skills';

    // Map icons to categories
    const categoryIcons = {
        'Technische Vaardigheden': <FaCode className="mr-2" />,
        'Cloud en DevOps': <FaCloud className="mr-2" />,
        'Beveiliging en Compliance': <FaLock className="mr-2" />,
        'Samenwerking en Methodologieën': <FaLightbulb className="mr-2" />,
    };

    // Map category names to translations
    const categoryNames = {
        'Technische Vaardigheden': language === 'en' ? 'Technical Skills' : 'Technische Vaardigheden',
        'Cloud en DevOps': language === 'en' ? 'Cloud and DevOps' : 'Cloud en DevOps',
        'Beveiliging en Compliance': language === 'en' ? 'Security and Compliance' : 'Beveiliging en Compliance',
        'Samenwerking en Methodologieën': language === 'en' ? 'Collaboration and Methodologies' : 'Samenwerking en Methodologieën',
    };

    return (
        <section
            id="skills"
            className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md transform transition-all duration-300 ${
                isActive ? 'scale-105 shadow-2xl' : 'hover:scale-105 hover:shadow-lg'
            }`}
        >
            <h2 className="text-2xl font-bold mb-6 dark:text-white flex items-center">
                {language === 'en' ? 'Skills' : 'Vaardigheden'}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {data.skills &&
                    data.skills.map((categoryItem, index) => (
                        <div
                            key={index}
                            className="skill-category bg-gray-50 dark:bg-gray-700 p-5 rounded-md shadow-sm"
                        >
                            <h3 className="text-xl font-semibold dark:text-white flex items-center mb-4">
                                {categoryIcons[categoryItem.category] || <FaTools className="mr-2" />}
                                {categoryNames[categoryItem.category]}
                            </h3>
                            <div>
                                {categoryItem.skills.map((skillItem, idx) => (
                                    <div
                                        key={idx}
                                        className={`flex items-center py-2 flex-wrap ${
                                            idx < categoryItem.skills.length - 1
                                                ? 'border-b border-gray-200 dark:border-gray-600'
                                                : ''
                                        }`}
                                    >
                                        <span className="text-gray-700 dark:text-gray-300 break-all">{skillItem.name}</span>
                                        <span className="ml-auto text-gray-500 dark:text-gray-400 break-all">{skillItem.level}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
            </div>
        </section>
    );
};

export default Skills;