// App.js
import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Experience from './components/Experience';
import Education from './components/Education';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Internship from './components/Internship';
import Personal from './components/Personal';
import dataNL from './data.json';
import dataEN from './data-en.json';
import Masonry from 'react-masonry-css';

const App = () => {
    const [language, setLanguage] = useState('nl');
    const [data, setData] = useState(dataNL);
    const [darkMode, setDarkMode] = useState(true); // Default to dark mode
    const [activeSection, setActiveSection] = useState('');
    const [isHeaderVisible] = useState(true);

    useEffect(() => {
        setData(language === 'nl' ? dataNL : dataEN);
    }, [language]);

    useEffect(() => {
        if (darkMode) {
            document.documentElement.classList.add('dark');
            localStorage.setItem('theme', 'dark');
        } else {
            document.documentElement.classList.remove('dark');
            localStorage.setItem('theme', 'light');
        }
    }, [darkMode]);

    const breakpointColumnsObj = {
        default: 2,
        1024: 2, // 2 columns for large screens
        768: 1,  // 1 column for medium screens
        640: 1,  // 1 column for small screens
    };


    const {phone, } = data.basics;

    return (
        <div className={`min-h-screen font-mono ${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
            {isHeaderVisible && (
                <Header
                    setLanguage={setLanguage}
                    language={language}
                    darkMode={darkMode}
                    setDarkMode={setDarkMode}
                    setActiveSection={setActiveSection}
                />
            )}
            <main className={`p-4 ${isHeaderVisible ? 'mt-6' : ''} flex flex-col md:flex-row`}>
                {/* Personal Card */}
                <div className="w-full md:w-1/4 min-w-[250px] p-4">
                    <div className={`sticky ${isHeaderVisible ? 'top-6' : 'top-0'}`}>
                        <Personal language={language} data={data} phone={phone} />
                    </div>
                </div>
                {/* Masonry Layout */}
                <div className="flex-1 p-4">
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="flex w-auto -ml-4"
                        columnClassName="pl-4 bg-clip-padding"
                    >
                        <div className="mb-4">
                            <Internship language={language} activeSection={activeSection} data={data}/>
                        </div>
                        <div className="mb-4">
                            <Experience language={language} activeSection={activeSection} data={data}/>
                        </div>
                        <div className="mb-4">
                            <Education language={language} activeSection={activeSection} data={data}/>
                        </div>
                        <div className="mb-4">
                            <Projects language={language} activeSection={activeSection} data={data}/>
                        </div>
                        <div className="mb-4">
                            <Skills language={language} activeSection={activeSection} data={data}/>
                        </div>
                    </Masonry>
                </div>
            </main>
        </div>
    );
};

export default App;