import React from 'react';
import { format, parseISO } from 'date-fns';
import { FaBriefcase } from 'react-icons/fa';

const Internship = ({ language, activeSection, data }) => {
    const isActive = activeSection === 'internship';

    const formatDate = (dateString) => {
        if (!dateString) return language === 'en' ? 'Present' : 'Heden';
        const date = parseISO(dateString);
        return format(date, 'MMM yyyy');
    };

    return (
        <section
            id="internship"
            className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md transform transition-all duration-300 ${
                isActive ? 'scale-105 shadow-2xl' : 'hover:scale-105 hover:shadow-lg'
            }`}
        >
            <h2 className="text-2xl font-bold mb-6 dark:text-white flex items-center">
                <FaBriefcase className="mr-2" />
                {language === 'en' ? 'Internship' : 'Stage'}
            </h2>
            <div className="space-y-8">
                {data.internships &&
                    data.internships.map((internship, index) => (
                        <div
                            key={index}
                            className="internship-item bg-gray-50 dark:bg-gray-700 p-5 rounded-md shadow-sm"
                        >
                            <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                                <div>
                                    <h3 className="text-xl font-semibold dark:text-white">{internship.position}</h3>
                                    <p className="text-md text-gray-600 dark:text-gray-300">
                                        {internship.name} - {internship.location}
                                    </p>
                                </div>
                                <div className="mt-2 md:mt-0 text-sm text-gray-500 dark:text-gray-400 md:text-right">
                                    <p>
                                        {formatDate(internship.startDate)} - {formatDate(internship.endDate)}
                                    </p>
                                </div>
                            </div>
                            <ul className="mt-4 list-disc list-inside text-gray-700 dark:text-gray-200">
                                {internship.highlights.map((highlight, idx) => (
                                    <li key={idx}>{highlight}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
            </div>
        </section>
    );
};

export default Internship;
